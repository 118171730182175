<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.unidades_pend_instalar > 0"
      >
        <v-chip
          label
          color="warning"
          dark
          small
          class="ml-1"
        >
          {{ formattedItem.unidades_pend_instalar }} {{ 'unidad'|pluralize(formattedItem.unidades_pend_instalar, 'es') }} pend. instalar
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.descripcion} (${item.codigo})`)
      item.subtitle = item.observacion
      item.avatar = item.unidades
      item.avatarColor = 'info'
      return item
    }
  },
}
</script>
